<template>
  <v-row justify="center">

    <v-dialog v-model="dialog" fullscreen :scrim="false" persistent transition="dialog-bottom-transition" class="v-dialog"
      style="width: 100% !important">
      <template v-slot:activator="{ props }">
        <v-btn color="primary" dark v-bind="props"> Open Dialog </v-btn>
      </template>
      <v-card v-if="Carregando_Maquina">
        <v-toolbar class="header" justify="center">
          <v-btn icon dark @click="this.$emit('OnTodasMaquinas');
          this.$emit('FecharModalApontamentoAgrupado');" style="color: #fff; position: absolute">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <div style="width: 100vw; display: flex; justify-content: center">
            <div class="div-maquina-selecionada">
              <v-autocomplete label="Máquina" density="compact" color="#FFF"
                style="font-weight: bold; color: #fff; margin-top: 20px" :items="arrayMaquinas"
                v-model="MaquinaSelecionada" :auto-select-first="true" clearable :autofocus="true"></v-autocomplete>
            </div>
          </div>

          <v-btn @click="AtualizaLista()" style="color: #000; background: #ffca2c;">
            Atualizar Lista
          </v-btn>
        </v-toolbar>

        <div v-if="mostrar_os" style="height: 100vh">
          <OSApontaAgrupado ref="OSApontaAgrupado" :arrayApontaAgrupados="arrayApontaAgrupados"
            :arrayItensApontamentos="arrayItensApontamentos" :mensagem="mensagem" :host="host"
            :MaquinaSelecionada="MaquinaSelecionada" :versaoClient="versaoClient" @OnMotivoDeParada="OnMotivoDeParada"
            @AtualizaArray="AtualizaArray" @AtualizaStatusApontamento="AtualizaStatusApontamento" @AtualizaLista="AtualizaLista"/>
        </div>

        <div v-if="mostrar_os == false" style="display: flex; align-items: center; height: 100%">
          <ProgressCharts color="#004689" :size="100" :width="6" />
        </div>
      </v-card>
      <div v-else style="display: flex; align-items: center; height: 100%">
        <ProgressCharts color="#004689" :size="100" :width="6" />
      </div>
    </v-dialog>
  </v-row>
</template>
<script>
import { RequestGETSQL } from "../../../../../../CPSWeb/front-end/services/ApiRequest.js";

import OSApontaAgrupado from "./OSApontaAgrupado.vue";

import ProgressCharts from "../ProgressCharts.vue";

import { ref } from "vue";

export default {
  name: "ApontamentoAgrupado",

  components: {
    OSApontaAgrupado,
    ProgressCharts,
  },

  data() {
    return {
      dialog: false,

      // Máquinas
      arrayMaquinas: [],
      arrayMaquinasCompleto: [],
      arrayApontaAgrupados: [],
      arrayItensApontamentos: [],
      dadosTapontaAberto: [],
      MaquinaSelecionada: "",
      cod_maquina: 0,
      cod_tp_servico: 0,

      mostrar_os: false,
      Carregando_Maquina: false,
      mensagem: true,
    };
  },

  props: {
    ativar_modal: Boolean,
    versaoClient: String,
    host: String,
  },

  emits: ["FecharModalApontamentoAgrupado", "OnTodasMaquinas", "OnMotivoDeParada", "OnHabilitaBtnsCards", "OnHabilitaDIVPrincipal", "AbrindoApontamento", "AvisoAbrirApontamentoAgrupado"],

  created() {
    this.dialog = this.ativar_modal;

    this.checaApontamentosAbertos();
    this.ArrayUltimaMaquinaSelecionada();
    this.GetMaquinas();
  },

  watch: {
    MaquinaSelecionada(newValue) {
      try {
        if (newValue == ""){
          this.mensagem = true
        }else{
          this.mensagem = false
        };
        
        let resultado = newValue.match(/(\d+) -/);
        this.cod_maquina = parseInt(resultado[1]);
        sessionStorage.setItem("cod_maquina", this.cod_maquina);

        let arrayFuncionarios = [];
        arrayFuncionarios = JSON.parse(localStorage.getItem("ultima_maquina_selecionada"));

        // Atualiza o sessionStorage ultima_maquina_selecionada com a nova máquina selecionada
        for (let i = 0; i < arrayFuncionarios.length; i++) {
          if (sessionStorage.getItem("cod_funcionario") == arrayFuncionarios[i].cod_funcionario) {
            arrayFuncionarios[i].maquina = newValue;
            break;
          }
        }

        localStorage.setItem("ultima_maquina_selecionada", JSON.stringify(arrayFuncionarios));
        this.TrocarMaquina();

      } catch { }
    },
  },

  methods: {
    GetMaquinas() {
      this.MaquinaSelecionada = "";

      const JsonSend = {
        SQL: `select codigo, nome, cod_tp_maquina from tmaquina 
            where cod_empresa = 1 and executa_varios_servicos = 1 and inativo = 0 and nao_fechar_todos_apontame = 0`,
        Params: "",
      };

      RequestGETSQL(this.host,
        localStorage.getItem("chave_mensageria"),
        localStorage.getItem("token"),
        JsonSend
      )
        .then(this.afterGetMaquinas)
        .catch(this.OnError);
    },

    afterGetMaquinas(res) {
      this.arrayMaquinas = [];
      this.arrayMaquinasCompleto = [];

      this.arrayMaquinasCompleto = res.data;

      for (let i = 0; i < res.data.length; i++) {
        this.arrayMaquinas.push(res.data[i].codigo + " - " + res.data[i].nome);
      }

      this.TrocarMaquina();
    },

    TrocarMaquina() {
      this.mostrar_os = false;
      this.montaArrays();
    },

    montaArrays() {
      let JsonSend = "";

      // Verifica tipo do banco
      if (localStorage.getItem("tipo_banco") == "SQLServer") { // SQL
        JsonSend = {
          SQL: `
            SELECT CASE
                  WHEN exists 
                  (SELECT top 1 TAPONTA_ABERTO.COD_BARR 
                    FROM TAPONTA_ABERTO 
                    WHERE TAPONTA_ABERTO.COD_BARR = TAPONT_GRP_PP.COD_PP) THEN 1 
                  ELSE 0
                  END EM_EXECUCAO,
            COALESCE(TAPONTA_ABERTO.MOTIVO_PARADA, 0) AS 'MOTIVO_PARADA',
                  TAPONT_GRP_PP.COD_TAPONT_GRP,
                  TAPONT_GRP_PP.GUID_LINHA,
                  TAPONT_GRP_PP.COD_PP,
                  TAPONT_GRP_PP.QTDE,
                  TAPONT_GRP_PP.COD_TP_SERVICO,
                  TAPONT_GRP_PP.TIPO_SERVICO,
                  TAPONT_GRP_PP.OBS_PP,
                  TPRO_PRO.COD_EMPRESA,
                  TPRO_PRO.COD_OS,
                  TPRO_PRO.COD_OS_AUX,
                  TPRO_PRO.COD_OS_COMPLETO,
                  TPRO_PRO.SEQ AS PP_SEQ,
                  TPRODUTO.CODIGO_INTERNO AS PRODUTO_COD_INTERNO,
                  TPRODUTO.NOME AS PRODUTO_NOME,
                  TPRO_PRO.OBS AS PP_OBS,
                  TOS_AUX.SUBTITULO AS OS_SUBTITULO,
                  TOS_AUX.N_DESENHO AS N_DESENHO,
                  TOS_AUX.REVISAO_DESENHO AS REVISAO_DESENHO,
                  TOS_AUX.POSICAO_DESENHO AS POSICAO_DESENHO,

                  TAPONT_GRP.CODIGO,
                  DESCRICAO,
                  TAPONT_GRP.LOG,
                  CAST(CAST(TAPONT_GRP.CODIGO AS VARCHAR(10)) + ' - ' + DESCRICAO AS VARCHAR(350)) AS LINHA_01,
                  CASE
                    WHEN COALESCE(
                            (SELECT SUM(1)
                            FROM TAPONTA_ABERTO
                            WHERE EXISTS
                              (SELECT TAPONT_GRP_PP.COD_PP
                                FROM TAPONT_GRP_PP
                                WHERE TAPONT_GRP_PP.COD_PP = TAPONTA_ABERTO.COD_BARR
                                AND TAPONT_GRP_PP.COD_TAPONT_GRP = TAPONT_GRP.CODIGO)), 0) = 0 THEN 0
                    ELSE 1
                  END AS QTDE_APONT

                  FROM TAPONT_GRP_PP
                  JOIN TAPONT_GRP ON TAPONT_GRP_PP.COD_TAPONT_GRP = TAPONT_GRP.CODIGO
                  JOIN TPRO_PRO ON TAPONT_GRP_PP.COD_PP = TPRO_PRO.CODIGO
                  JOIN TOS ON TPRO_PRO.COD_EMPRESA = TOS.COD_EMPRESA
                  AND TPRO_PRO.COD_OS = TOS.CODIGO
                  JOIN TOS_AUX ON TPRO_PRO.COD_EMPRESA = TOS_AUX.COD_EMPRESA
                  AND TPRO_PRO.COD_OS = TOS_AUX.COD_OS
                  AND TPRO_PRO.COD_OS_AUX = TOS_AUX.CODIGO
            LEFT JOIN TAPONTA_ABERTO ON TAPONTA_ABERTO.COD_BARR = TPRO_PRO.CODIGO
                  LEFT JOIN TPRODUTO ON TOS.COD_EMPRESA = TPRODUTO.COD_EMPRESA
                  AND TOS.COD_PECAS = TPRODUTO.CODIGO_INTERNO
                  WHERE coalesce(TAPONT_GRP.COD_STATUS, 0) = 1
                  AND coalesce(TPRO_PRO.FINALIZADO, 0) = 0
                  AND coalesce(TOS.APROVADO, 0) = 1
                  ORDER BY EM_EXECUCAO DESC, 
                  TAPONT_GRP_PP.COD_TAPONT_GRP,
                  TAPONT_GRP_PP.COD_PP;
                `,
          Params: "",
        };
      } else { // PG
        JsonSend = {
          SQL: `
            SELECT CASE
                  WHEN exists 
                  (SELECT TAPONTA_ABERTO.COD_BARR 
                    FROM TAPONTA_ABERTO 
                    WHERE TAPONTA_ABERTO.COD_BARR = TAPONT_GRP_PP.COD_PP LIMIT 1) THEN 1 
                  ELSE 0
                  END EM_EXECUCAO,
            COALESCE(TAPONTA_ABERTO.MOTIVO_PARADA, 0) AS MOTIVO_PARADA,
                  TAPONT_GRP_PP.COD_TAPONT_GRP,
                  TAPONT_GRP_PP.GUID_LINHA,
                  TAPONT_GRP_PP.COD_PP,
                  TAPONT_GRP_PP.QTDE,
                  TAPONT_GRP_PP.COD_TP_SERVICO,
                  TAPONT_GRP_PP.TIPO_SERVICO,
                  TAPONT_GRP_PP.OBS_PP,
                  TPRO_PRO.COD_EMPRESA,
                  TPRO_PRO.COD_OS,
                  TPRO_PRO.COD_OS_AUX,
                  TPRO_PRO.COD_OS_COMPLETO,
                  TPRO_PRO.SEQ AS PP_SEQ,
                  TPRODUTO.CODIGO_INTERNO AS PRODUTO_COD_INTERNO,
                  TPRODUTO.NOME AS PRODUTO_NOME,
                  TPRO_PRO.OBS AS PP_OBS,
                  TOS_AUX.SUBTITULO AS OS_SUBTITULO,
                  TOS_AUX.N_DESENHO AS N_DESENHO,
                  TOS_AUX.REVISAO_DESENHO AS REVISAO_DESENHO,
                  TOS_AUX.POSICAO_DESENHO AS POSICAO_DESENHO,

                  TAPONT_GRP.CODIGO,
                  DESCRICAO,
                  TAPONT_GRP.LOG,
                  CONCAT(TAPONT_GRP.CODIGO ::text, ' - ',  DESCRICAO ::text) AS LINHA_01,
                  CASE
                    WHEN COALESCE(
                            (SELECT SUM(1)
                            FROM TAPONTA_ABERTO
                            WHERE EXISTS
                              (SELECT TAPONT_GRP_PP.COD_PP
                                FROM TAPONT_GRP_PP
                                WHERE TAPONT_GRP_PP.COD_PP = TAPONTA_ABERTO.COD_BARR
                                AND TAPONT_GRP_PP.COD_TAPONT_GRP = TAPONT_GRP.CODIGO)), 0) = 0 THEN 0
                    ELSE 1
                  END AS QTDE_APONT

                  FROM TAPONT_GRP_PP
                  JOIN TAPONT_GRP ON TAPONT_GRP_PP.COD_TAPONT_GRP = TAPONT_GRP.CODIGO
                  JOIN TPRO_PRO ON TAPONT_GRP_PP.COD_PP = TPRO_PRO.CODIGO
                  JOIN TOS ON TPRO_PRO.COD_EMPRESA = TOS.COD_EMPRESA
                  AND TPRO_PRO.COD_OS = TOS.CODIGO
                  JOIN TOS_AUX ON TPRO_PRO.COD_EMPRESA = TOS_AUX.COD_EMPRESA
                  AND TPRO_PRO.COD_OS = TOS_AUX.COD_OS
                  AND TPRO_PRO.COD_OS_AUX = TOS_AUX.CODIGO
            LEFT JOIN TAPONTA_ABERTO ON TAPONTA_ABERTO.COD_BARR = TPRO_PRO.CODIGO
                  LEFT JOIN TPRODUTO ON TOS.COD_EMPRESA = TPRODUTO.COD_EMPRESA
                  AND TOS.COD_PECAS = TPRODUTO.CODIGO_INTERNO
                  WHERE coalesce(TAPONT_GRP.COD_STATUS, 0) = 1
                  AND coalesce(TPRO_PRO.FINALIZADO, 0) = 0
                  AND coalesce(TOS.APROVADO, 0) = 1
                  ORDER BY EM_EXECUCAO DESC,
                  TAPONT_GRP_PP.COD_TAPONT_GRP,
                  TAPONT_GRP_PP.COD_PP;
                `,
          Params: "",
        };
      }

      RequestGETSQL(this.host,
        localStorage.getItem("chave_mensageria"),
        localStorage.getItem("token"),
        JsonSend
      )
        .then(this.afterMontaArrays)
        .catch(this.OnError);
    },

    afterMontaArrays(res) {
      this.arrayApontaAgrupados = [];
      this.arrayItensApontamentos = [];
      let arrayCodigos = [];

      // Pega o cod_tp_maquina da máquina selecionada
      for (let i = 0; i < this.arrayMaquinasCompleto.length; i++) {
        if (this.arrayMaquinasCompleto[i].codigo == this.cod_maquina) {
          this.cod_tp_servico = this.arrayMaquinasCompleto[i].cod_tp_maquina;
        }
      }

      this.checaApontamentosAbertos();

      for (let i = 0; i < res.data.length; i++) {
        if (!arrayCodigos.includes(res.data[i].codigo) && res.data[i].cod_tp_servico == this.cod_tp_servico) {
          const objeto = {
            codigo: res.data[i].codigo,
            descricao: res.data[i].descricao,
            log: res.data[i].log,
            linha_01: res.data[i].linha_01,
            qtde_apont: res.data[i].qtde_apont,
            motivo_parada: res.data[i].motivo_parada,
            em_execucao: res.data[i].em_execucao
          }

          this.arrayApontaAgrupados.push(objeto);

          for (let j = 0; j < this.dadosTapontaAberto.length; j++){
            if (res.data[i].cod_pp == this.dadosTapontaAberto[j].cod_barr && this.cod_maquina != this.dadosTapontaAberto[j].cod_maquina){
              this.arrayApontaAgrupados.pop();
            }
          }
        }

        arrayCodigos.push(res.data[i].codigo);

        // arrayItensApontamentos
        this.arrayItensApontamentos.push(res.data[i]);

        if (res.data[i].pp_obs != null) { // Base64 para string
          this.arrayItensApontamentos[this.arrayItensApontamentos.length - 1].pp_obs = atob(res.data[i].pp_obs);
        }
      }

      this.mostrar_os = true;
      this.Carregando_Maquina = true;
    },

    checaApontamentosAbertos(){
      const JsonSend = {
          SQL: `
              select cod_barr, cod_maquina from taponta_aberto;
                `,
          Params: "",
        };

      RequestGETSQL(this.host,
        localStorage.getItem("chave_mensageria"),
        localStorage.getItem("token"),
        JsonSend
      )
        .then(this.afterChecaApontamentosAbertos)
        .catch(this.OnError);
    },

    afterChecaApontamentosAbertos(res){
      this.dadosTapontaAberto = res.data;
    },

    AtualizaStatusApontamento() {
      this.montaArrays();

      setTimeout(() => {
        document.querySelector(
          "#aponta_agrupados" + sessionStorage.getItem("cod_agrupamento")
        ).style.backgroundColor = "#a9a9a9";
      }, 10);
    },

    AtualizaLista() {
      this.ArrayUltimaMaquinaSelecionada();
      this.GetMaquinas();
      this.cod_tp_servico = null;
      this.mostrar_os = false;

      setTimeout(() => {
        this.mostrar_os = true;
      }, 1000)
    },

    OnMotivoDeParada(res) {
      this.$emit("OnMotivoDeParada", res, "apontamento_agrupado");
    },

    OnError(erro) {
      console.error(erro);
    },

    // Exclui o apontamento agrupado que foi finalizado
    AtualizaArray(codigo) {
      for (let i = 0; i < this.arrayApontaAgrupados.length; i++) {
        if (this.arrayApontaAgrupados[i].codigo == codigo) {
          this.arrayApontaAgrupados.splice(i, 1);
        }
      }
    },

    ResetaMP() {
      this.$refs.OSApontaAgrupado.reloadDivs(false);

      setTimeout(() => {
        this.$refs.OSApontaAgrupado.resetaMPdoComponentePai();
      }, 1000);
    },

    ErroMP(mensagem){
      this.$refs.OSApontaAgrupado.ErroMP(mensagem);
    },

    CancelaMP() {
      this.$refs.OSApontaAgrupado.disabledBtns(false);
    },

    async ArrayUltimaMaquinaSelecionada() {
      let arrayFuncionarios = [];

      if (localStorage.getItem("ultima_maquina_selecionada") != null) {
        arrayFuncionarios = JSON.parse(localStorage.getItem("ultima_maquina_selecionada"));
        var verificaseTemFuncionario = false;

        // Tenta achar o cod_funcionario no sessionStorage
        for (let i = 0; i < arrayFuncionarios.length; i++) {
          if (sessionStorage.getItem("cod_funcionario") == arrayFuncionarios[i].cod_funcionario && arrayFuncionarios[i].maquina != "") {
            let resultado = arrayFuncionarios[i].maquina.match(/(\d+) -/);
            const cod_maquina = parseInt(resultado[1]);

            const JSonSend = {
              SQL: `select inativo from tmaquina where cod_empresa = 1 and nao_fechar_todos_apontame = 0 and codigo = ` + cod_maquina,
              Params: ""
            }

            const result = await RequestGETSQL(this.host,
              localStorage.getItem("chave_mensageria"),
              localStorage.getItem("token"),
              JSonSend
            ).catch((erro) => {
              return erro.request;
            });

            if (result.data[0] == 0){
              this.MaquinaSelecionada = arrayFuncionarios[i].maquina;
            }else{
              this.MaquinaSelecionada = "";
              this.cod_maquina = null
            }

            verificaseTemFuncionario = true;
          }
        }

        // Caso nao exista o funcionario
        if (!verificaseTemFuncionario) {
          const objeto = {
            cod_funcionario: sessionStorage.getItem("cod_funcionario"),
            maquina: this.MaquinaSelecionada
          }

          this.mensagem = true;
          arrayFuncionarios.push(objeto);
        }
      } else { // Caso seja o primeiro acesso, ele cria o sessionStorage("ultima_maquina_selecionada")
        const objeto = {
          cod_funcionario: sessionStorage.getItem("cod_funcionario"),
          maquina: this.MaquinaSelecionada // aqui setando o valor maquina: ""
        }

        this.mensagem = true;
        arrayFuncionarios.push(objeto);
      }

      localStorage.setItem("ultima_maquina_selecionada", JSON.stringify(arrayFuncionarios));
    },
  },
};
</script>

<style scoped>
.header {
  background: #004c89;
  height: 8vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.div-maquina-selecionada {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (orientation: portrait) and (max-width: 480px) {
  .header {
    height: 10%;
  }
}
</style>