<template>
    <DialogErro :show="this.showDialog" @show-dialog="this.showDialog = false" :message="this.mensagemAviso"/>

    <div v-if="mostrar_animacao == false" style="display: flex; align-items: center; height: 100%">
        <ProgressCharts color="#004689" :size="100" :width="6" />
    </div>

    <v-row v-else cols="12" style="width: 100%; height: 100%; margin: 0 !important; margin-top: -30px !important;">
        <v-col cols="12" xs="12" justify="center" sm="5" style="border-right: 2px solid #808080; padding-left: 12px;">
            <!-- <h5 style="width: 100%;">Itens Disponíveis: {{ arrayItensApontamentos.length }}</h5> -->
            <h5 style="width: 100%;">Itens Disponíveis: {{ length_array }}</h5> 

            <div class="div_lista_disponiveis" v-show="resource.finalizarParcial" v-for="resource in arrayItensApontamentos" :key="resource.guid_linha" 
            style="margin-bottom: 10px; border: 1px solid #808080; border-radius: 5px; cursor: pointer;" @click="SelecionarItem(resource)">
                <h1>
                    <b>OS: {{ resource.cod_os_completo }} -
                        {{ resource.produto_nome }} - QTDE: {{ resource.qtde.toFixed(2) }}
                    </b>
                </h1>
                <h1>
                OP: {{ resource.pp_seq }} - [{{ resource.n_desenho }}({{
                    resource.revisao_desenho
                }})({{ resource.posicao_desenho }})] - {{ resource.tipo_servico }} -
                N°Proc: {{ resource.cod_pp }}
                </h1>
                <h1>
                {{ resource.cod_os_completo }} - {{ resource.produto_cod_interno }}
                {{ resource.produto_nome }}
                </h1>
                <h1 v-if="resource.pp_obs != null">
                {{ resource.pp_obs }}
                </h1>

                <div style="width: 100%; display: flex; justify-content: flex-end;">
                    <v-btn
                        style="
                        width: 25%;
                        background: #157347;
                        color: white;
                        font-weight: bold;
                        margin: 0 10px 10px 0;
                        "
                    >
                        Selecionar
                    </v-btn>
                </div>
            </div>
        </v-col>

        <v-col cols="12" xs="12" sm="5">
            <h5 style="width: 100%;">Itens Selecionados: {{ arrayItensSelecionados.length }}</h5> 

            <div class="div_lista_selecionados" v-for="resource in arrayItensSelecionados" :key="resource.guid_linha" 
            style="margin-bottom: 10px; border: 1px solid #808080; border-radius: 5px; cursor: pointer" @click="RemoverItem(resource)">
                <h1>
                    <b>OS: {{ resource.cod_os_completo }} -
                        {{ resource.produto_nome }} - QTDE: {{ resource.qtde.toFixed(2) }}
                    </b>
                </h1>
                <h1>
                OP: {{ resource.pp_seq }} - [{{ resource.n_desenho }}({{
                    resource.revisao_desenho
                }})({{ resource.posicao_desenho }})] - {{ resource.tipo_servico }} -
                N°Proc: {{ resource.cod_pp }}
                </h1>
                <h1>
                {{ resource.cod_os_completo }} - {{ resource.produto_cod_interno }}
                {{ resource.produto_nome }}
                </h1>
                <h1 v-if="resource.pp_obs != null">
                {{ resource.pp_obs }}
                </h1>

                <div style="width: 100%; display: flex; justify-content: flex-end;">
                    <v-btn
                        style="
                        width: 25%;
                        background: black;
                        color: white;
                        font-weight: bold;
                        margin: 0 10px 10px 0;
                        "
                    >
                        Remover
                    </v-btn>
                </div>
            </div>
        </v-col>

        <v-col cols="12" xs="12" sm="2" justify="center" style="border-left: 2px solid #a9a9a9; display: block;">
            <v-btn
                @click="FinalizarApontamentosSelecionados()"
                style="
                width: 100%;
                height: 70px;
                background: #ffca2c;
                color: black;
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 10px;
                "
            >
                <div style="margin: 0 !important;">
                    <p id="btn-text-style" style="font-size: 14px;">
                        Finalizar<br />
                        Apontamentos<br />
                        Selecionados<br />
                    </p>
                </div>
            </v-btn>
            <v-btn
                @click="this.$emit('FecharFinalizarParcial')"
                style="
                width: 100%;
                height: 70px;
                background: red;
                color: white;
                font-weight: bold;
                font-size: 14px;
                "
            >
                Cancelar
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import {RequestPostFinalizarApontamentoAgrupadoParcial} from "../../../../../../../CPSWeb/front-end/services/ApiRequest.js";

import DialogErro from "../../DialogErro.vue";

import ProgressCharts from "../../ProgressCharts.vue";

export default{
    name: "FinalizarParcial",

    components: {
        DialogErro,
        ProgressCharts
    },

    emits: ["FecharFinalizarParcial", "AddItemRemovido", "RemoverItemSelecionado", "OnError", "AtualizaLista"],

    props: {
        host: String,
        arrayItensApontamentos: Array,
        length_array: Number,
    },

    data(){
        return{
            arrayItensSelecionados: [],
            showDialog: false,
            mensagemAviso: "",
            mostrar_animacao: true
        }
    },

    methods: {
        FinalizarApontamentosSelecionados(){
            if (this.length_array == 0){
                this.showDialog = true;
                this.mensagemAviso = "Todos os itens do apontamento agrupado foram selecionados. Para finalizar todos de uma só vez, opte pela finalização completa do apontamento.";    
            }
            else if (this.arrayItensSelecionados.length == 0){
                this.showDialog = true;
                this.mensagemAviso = "Selecione os itens a serem finalizados.";  
            }
            else{
                let guid_linhas = "";
    
                for (let i = 0; i < this.arrayItensSelecionados.length; i++){
                    guid_linhas += "'" + this.arrayItensSelecionados[i].guid_linha + "'" + ",";
                }
    
                // Removo a vírgula que sobrou no final da string
                guid_linhas = guid_linhas.substring(0, guid_linhas.length - 1);
    
                const body = {
                    cod_empresa: this.arrayItensSelecionados[0].cod_empresa,
                    cod_maquina: sessionStorage.getItem("cod_maquina"),
                    cod_funcionario: sessionStorage.getItem("cod_funcionario"),
                    cod_agrupamento: this.arrayItensSelecionados[0].codigo,
                    versao_client: this.versaoClient,
                    guid_linhas: guid_linhas,
                };

                this.mostrar_animacao = false;
    
                RequestPostFinalizarApontamentoAgrupadoParcial(
                    this.host,
                    localStorage.getItem("chave_mensageria"),
                    localStorage.getItem("token"),
                    body
                )
                .then(this.afterFinalizarApontamentosSelecionados)
                .catch(this.OnError);
            }
        },

        afterFinalizarApontamentosSelecionados(res){
            this.$emit('AtualizaLista');
            this.mostrar_animacao = true;
        },

        OnError(erro){
            this.mostrar_animacao = true;
            this.$emit("OnError", erro);
        },

        SelecionarItem(res){
            this.arrayItensSelecionados.push(res);

            this.$emit('RemoverItemSelecionado', res);
        },

        RemoverItem(res){
            let index = -1;
            for (let i = 0; i < this.arrayItensSelecionados.length; i++) {
                if (res.cod_os_completo == this.arrayItensSelecionados[i].cod_os_completo) {
                    index = i;
                }
            }

            if (index > -1) {
                this.arrayItensSelecionados.splice(index, 1);
            }

            this.$emit('AddItemRemovido', res);
        }
    }
}
</script>

<style scoped>
p{
    margin: 0;
}

h1 {
  font-size: 16px;
  margin-left: 10px;
}

h5 {
  background: #545454;
  color: #fff;
  padding: 5px;
}

@media (max-width: 600px) {
  h5 {
    margin-left: 10px;
  }

  .div_lista_disponiveis {
    margin: 0 10px;
  }
}
</style>